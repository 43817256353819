<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
  <div>
    <button type="button" @click="onInstall">Установить как приложение</button>
  </div>

  </div>
</template>

<script>
export default {
  computed: {
    installPromt() {
      return this.$store.state.installPrompt;
    }
  },
  methods: {
    onInstall() {
      this.installPrompt.prompt();
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
